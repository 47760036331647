/* eslint-disable no-unused-vars */
// import { db } from '../firebase';
// import { collection, doc } from 'firebase/firestore';
// import {
//   useCollectionData,
//   useDocumentData,
// } from 'react-firebase-hooks/firestore';

import classes from './HackerView.module.css';
import { Map } from './hacker/Map';
import { Console } from './hacker/Console';
import { Sitrep } from './hacker/Sitrep';
import { useState } from 'react';
import { useEventListener } from '../hooks/useEventListener';
import { DUMMY_DATA, KEYS } from '../constants';
import { ShutdownModal } from './hacker/ShutdownModal';
export const HackerView = () => {
  const [deployMode, setDeployMode] = useState('rebooting'); // online | offline | rebooting | shutdown
  const [selectedRunnerId, setSelectedRunnerId] = useState(0);
  const [runners, setRunners] = useState(DUMMY_DATA.RUNNERS);

  const handleKeyPress = ({ key }) => {
    if (KEYS.PGUP.includes(String(key))) {
      if (selectedRunnerId > 0) {
        setSelectedRunnerId(selectedRunnerId - 1);
      } else setSelectedRunnerId(DUMMY_DATA.RUNNERS.length - 1);
    } else if (KEYS.PGDOWN.includes(String(key))) {
      if (selectedRunnerId < DUMMY_DATA.RUNNERS.length - 1) {
        setSelectedRunnerId(selectedRunnerId + 1);
      } else setSelectedRunnerId(0);
    } else if (KEYS.PRINTSCREEN.includes(String(key))) {
      console.log('SHUTTING DOWN');
      setDeployMode('shutdown');
    } else console.log(key);
  };
  useEventListener('keydown', handleKeyPress);

  return (
    <main className={classes.hackerView}>
      {deployMode === 'shutdown' && (
        <ShutdownModal
          message="SHUTTING DOWN"
          cooldown={3}
          setDeployMode={setDeployMode}
          next="offline"
        />
      )}
      {deployMode === 'offline' && (
        <ShutdownModal
          message="OFFLINE"
          cooldown={0}
          setDeployMode={setDeployMode}
          next="offline"
        />
      )}
      {deployMode === 'rebooting' && (
        <ShutdownModal
          message="BOOTING UP"
          cooldown={2}
          setDeployMode={setDeployMode}
          next="online"
        />
      )}

      <header>
        <div className={classes.deckLogo}>ARASAKA SYSTEMS</div>
        <div>
          <div className={classes.linkContainer}>
            <div>Satlink Strength:</div>
            <div>🔴</div>
          </div>
          <div className={classes.traceWarning}>NO_TRACE_DETECTED</div>
        </div>
      </header>
      <Map
        runners={runners}
        selectedRunner={runners[selectedRunnerId]}
        hacker={DUMMY_DATA.HACKER}
        gamezone={DUMMY_DATA.GAMEZONE}
      />
      <footer>
        <Console />
        <Sitrep runners={runners} selectedRunnerId={selectedRunnerId} />
      </footer>
    </main>
  );
};
