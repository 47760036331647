import classes from './Sitrep.module.css';

export const Sitrep = props => {
  const { runners, selectedRunnerId } = props;

  const runnerDisplay = runner => (
    <div
      className={
        Number(runner.no) !== selectedRunnerId
          ? classes.runner
          : classes.selectedRunner
      }
      key={runner.no}
    >
      <div className={classes.runnerGridSt}>{runner.status}</div>
      <div className={classes.runnerGridNo}>{runner.no}</div>
      <div className={classes.runnerGridHa}>{runner.handle}</div>
      <div className={classes.runnerGridSo}>
        {runner.softs.map(soft => (
          <span className={classes.softLabel} key={runner + soft}>
            {soft}
          </span>
        ))}
      </div>
      <div className={classes.runnerGridCy}>{runner.cyc}</div>
    </div>
  );
  return (
    <div className={classes.sitrep}>
      <div className={classes.title}>SITREP</div>
      <div className={classes.runnerGrid}>
        <div className={classes.runnerGridHeader}>
          <div className={classes.runnerGridSt}>St.</div>
          <div className={classes.runnerGridNo}>#</div>
          <div className={classes.runnerGridHa}>Handle</div>
          <div className={classes.runnerGridSo}>Soft.</div>
          <div className={classes.runnerGridCy}>Cyc.</div>
        </div>
        {runners.map(runner => runnerDisplay(runner))}
      </div>
    </div>
  );
};
