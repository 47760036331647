import classes from './ShutdownModal.module.css';
import { useEffect, useState } from 'react';
export const ShutdownModal = props => {
  const { message, cooldown, setDeployMode, next } = props;
  const [cooldownTimer, setCooldownTimer] = useState(cooldown);

  useEffect(() => {
    if (cooldown > 0) {
      const interval = setInterval(() => {
        if (cooldownTimer > 0) setCooldownTimer(cooldownTimer - 1);
        if (cooldownTimer === 0) {
          clearInterval(interval);
          setDeployMode(next);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [cooldown, cooldownTimer, next, setDeployMode]);

  const modalWarning = (
    <div>
      <div>{message}</div>
      <div>{cooldown > 0 && cooldownTimer}</div>
    </div>
  );

  return (
    <div className={classes.background}>
      <div className={classes.modal}>{modalWarning}</div>
    </div>
  );
};
