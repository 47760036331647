export const DUMMY_DATA = {
  RUNNERS: [
    {
      id: 'rr00',
      status: 'online',
      no: '0',
      handle: 'Anon',
      softs: ['H/K', 'ICE'],
      cyc: 21,
      location: { lat: -33.88531502770797, lng: 151.17924912774666 },
    },
    {
      id: 'rr01',
      status: 'offline',
      no: '1',
      handle: 'Johnny 5',
      softs: [],
      cyc: 0,
      location: { lat: -33.884584683466855, lng: 151.18459208796062 },
    },
    {
      id: 'rr02',
      status: 'missing',
      no: '2',
      handle: 'Lord Nykon',
      softs: ['PROBE', 'SCAN', 'FORT'],
      cyc: 35,
      location: { lat: -33.88711414172761, lng: 151.18665202442867 },
    },
    {
      id: 'rr03',
      status: 'online',
      no: '3',
      handle: 'Renegade',
      softs: ['H/K', 'FORT'],
      cyc: 16,
      location: { lat: -33.88916259169249, lng: 151.19394763275298 },
    },
  ],
  GAMEZONE: {
    location: { lat: -33.88640704572304, lng: 151.19226033283445 },
    radius: 600,
  },
  HACKER: {
    id: 'rh00',
    location: { lat: -33.88640704572304, lng: 151.19226033283445 },
  },
};

export const KEYS = {
  PGUP: ['PageUp'],
  PGDOWN: ['PageDown'],
  PRINTSCREEN: ['Pause'],
};
