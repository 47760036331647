import { useMemo } from 'react';
import {
  GoogleMap,
  MarkerF,
  LoadScript,
  CircleF,
} from '@react-google-maps/api';
import HackerMarkerRed from '../../img/hacker-marker-sm-red.png';
import { hackerMapOptions } from './mapOptions';
import classes from './Map.module.css';
import { TeamMarkers } from './TeamMarkers';

export const Map = props => {
  const { hacker, runners, selectedRunner, gamezone } = props;
  const mapOptions = useMemo(() => hackerMapOptions, []);

  return (
    <div>
      {/* Need to work out why process.env doesn't work here */}
      <LoadScript googleMapsApiKey="AIzaSyCPV9Cdg2_xp9w5hLsXSuHzWQ7xUU9F-GE">
        <GoogleMap
          zoom={16}
          center={gamezone.location}
          mapContainerClassName={classes.mapContainer}
          options={mapOptions}
        >
          <TeamMarkers runners={runners} selectedRunner={selectedRunner} />
          <MarkerF
            icon={HackerMarkerRed}
            // Need to add an anchor point somehow
            options={{ anchorPoint: [0, 16] }}
            position={hacker.location}
            key="g"
          />
          <CircleF
            key="hackercircle"
            center={gamezone.location}
            options={{
              radius: gamezone.radius,
              visible: true,
              clickable: false,
              draggable: false,
              editable: false,
              strokeOpacity: 1,
              strokeWeight: 2,
              strokeColor: '#a00',
              fillColor: '#ff0000',
              fillOpacity: 0.01,
              zIndex: 1,
            }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};
