import { MarkerF } from '@react-google-maps/api';
import RunnerMarkerRed from '../../img/runner-marker-sm-red.png';
import RunnerMarkerPink from '../../img/runner-marker-sm-pink.png';

export const TeamMarkers = props => {
  const { runners, selectedRunner } = props;
  const runnerPins = runners.map(runner => (
    <MarkerF
      key={runner.id}
      icon={runner === selectedRunner ? RunnerMarkerPink : RunnerMarkerRed}
      position={runner.location}
      label={{ text: runner.no, color: 'white' }}
    />
  ));

  return runnerPins;
};
