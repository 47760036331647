import { useState, useRef } from 'react';
import classes from './Console.module.css';

const DUMMY_COMMANDS = [
  ['00:00', 'ping server 255.235.111.68', 'ok'],
  ['00:01', 'trace scan 400', 'ok'],
];

export const Console = () => {
  const [history, setHistory] = useState(DUMMY_COMMANDS);
  const [command, setCommand] = useState('');
  const commandLineEl = useRef(null);

  const refocus = () => {
    commandLineEl.current.focus();
  };

  const commandHistory = history.map((command, i) => (
    <div className={classes.command} key={i}>
      <span>{'[' + command[0] + '] '}</span>
      <span>{'> ' + command[1]}</span>
    </div>
  ));

  const parseCommand = () => {
    // send command to firestore functions & get response
    const newHistory = [...history, ['00:05', command, 'ok']];
    setHistory(newHistory);
    setCommand('');
  };

  return (
    <div className={classes.console}>
      <div className={classes.commandHistory}>{commandHistory}</div>
      <div className={classes.commandLine}>
        <span>{'[XX:XX] > '}</span>
        <input
          type="text"
          className={classes.commandLineInput}
          value={command}
          onKeyPress={e => e.key === 'Enter' && parseCommand()}
          onChange={e => setCommand(e.target.value)}
          ref={commandLineEl}
          onBlur={refocus}
          autoFocus
        />
      </div>
    </div>
  );
};
